import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";

const contextDefault = {
    accessToken: null,
    isLoggedIn: false,
    initial: true,
}

const Context = createContext(contextDefault)

export function useAuth() {
    return useContext(Context)
}

export function withAuthProvider(WrappedComponent) {
    return (props) => {
        const loadLoginState = useMemo(() => () => {
            const retrievedState = window.localStorage.getItem('loginState');
            if (retrievedState) {
                return {...JSON.parse(retrievedState),retrieved: true}
            } else {
                return contextDefault
            }
        },[])
        const [loginVars,setLoginVars] = useState(() => loadLoginState())
        //const {addNotification, clearNotifications} = useNotifications()

        const saveLoginState = useCallback((state) => {
            window.localStorage.setItem('loginState',JSON.stringify(state))
        },[])
        useEffect(() => {
            if (!loginVars.initial && !loginVars.retrieved) {
                saveLoginState(loginVars)
            }
        },[loginVars])
        const logout = () => {
            setLoginVars({
                accessToken: null,
                isLoggedIn: false
            })
            //clearNotifications()
            //addNotification({type: 'warning', message: 'Logged Out'})
        }
        const login = (accessToken) => {
            setLoginVars({
                accessToken,
                isLoggedIn: true
            })
            //clearNotifications()
            //addNotification({type: 'success', message: 'Logged In'})
        }
        const loginState = useMemo(() => {
            return {
                ...loginVars,
                login,
                logout
            }
        },[loginVars])
        return <Context.Provider value={loginState}>
            <WrappedComponent {...props} />
        </Context.Provider>
    }
}
