import {createContext, useCallback, useContext, useMemo, useState} from "react";

const Context = createContext({
    notifications: [

    ],
    addNotifications: () => {}
})

export const NotificationsContext = Context;

export const withNotificationsProvider = (WrappedComponent) => {
    return (props) => {
        const [notifications,setNotifications] = useState([

        ])
        const addNotification = useCallback((notification) => {
            console.log('notification: ',notification,setNotifications)
            setNotifications((notifications)=> {
                return [...notifications, notification]
            })
        },[setNotifications])
        const removeNotification = useCallback((notification) => {
            setNotifications((notifications)=> {
                const newNotifications = [...(notifications.filter(n => n !== notification))]
                return newNotifications
            })
        },[setNotifications])
        const clearAllNotifications = useCallback(() => {
            setNotifications([])
        },[setNotifications])
        const notificationsValue = useMemo(
            () => ({notifications,addNotification,removeNotification,clearAllNotifications,clearNotifications: clearAllNotifications}),
            [notifications,addNotification,removeNotification])
        return <Context.Provider value={notificationsValue}>
            <WrappedComponent {...props} />
        </Context.Provider>
    }
}

export const useNotifications = () => {
    return useContext(Context)
}

export const withNotifications = (WrappedComponent) => {
    return (props) => {
        return <WrappedComponent {...useNotifications()} {...props} />
    }
}

export const useErrorHandler = () => {
    const {addNotification} = useNotifications()
    return useCallback((error) => {
        addNotification({
            type: "Error",
            message: error.message
        })
    },[addNotification])
}