import React, {useEffect} from 'react';
import {userFields} from "../../../modules/users/user.fields";
import {useController} from "../../../mvc/mvc.hook";
import {MockUserModel} from "../../users/mock-user.model";
import {useNavigation} from "react-navi";
import {CrudViewItemView} from "../../../crud/view/CrudViewItemView";
import {UserController} from "../../../modules/users/user.controller";
import {useErrorHandler} from "../../../errors/error-handler";

export const ViewUser = ({id,base}) => {
    const navigation = useNavigation()
    const errorHandler = useErrorHandler();
    const {data,actions,loading} = useController(
        ({setData}) => new UserController(MockUserModel,navigation,base,errorHandler,id,setData),
        {
            asyncActions: true
        }
    )
    useEffect(() => {
        actions.loadInitialData().then(r => null)
    },[])
    return <CrudViewItemView {...{data,actions,loading}} fields={userFields} nameKey={'name'} />
}
