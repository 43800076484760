import React, {useEffect, useMemo, useState} from 'react';

const useIsMounted = () => {
    const ref = React.useRef(false)
    const [, setIsMounted] = React.useState(false)
    React.useEffect(() => {
        ref.current = true
        setIsMounted(true)
        return () => (ref.current = false)
    }, [])
    return () => ref.current
}

/*
export const useModel = (modelFactory,deps) => {
    const [data,setData] = useState(null)

    const model = useMemo(() => {
        return modelFactory({setData})
    },[].concat(deps))

    return {model,data,setData}
}
*/

export const useController = (controllerFactory,options,deps) => {

    const [data, setData] = useState(null)
    const isMounted = useIsMounted()

    const [loading,setLoading] = useState(undefined)

    useEffect(() => {
        if (options?.logging) {
            console.log("new data: ", data, "loading:", loading)
        }
    }, options?.logging?[data, loading]:[null,null])

    const controller = useMemo(() => {
        return controllerFactory({data,setData,setLoading,isMounted})
    },[(options?.stateless ? data : null)].concat(deps))

    const actions = useMemo(() => {
        const actions = {}
        function getInterceptor(key) {
            if (options?.asyncActions) {
                return (...args) => {
                    const ret = controller[key](...args)
                    if (typeof ret === "object" && typeof ret.then === "function") {
                        setLoading((loading) => ({...loading,[key]:[...args]}))
                        return ret.finally(() => {
                            isMounted() && setLoading((loading) => {
                                const _loading = {...loading}
                                delete _loading[key]
                                if (Object.keys(_loading).length === 0) {
                                    return undefined
                                } else {
                                    return _loading
                                }
                            })
                        })
                    } else {
                        return ret
                    }
                }
            } else {
                return (...args) => controller[key](...args)
            }
        }
        for (const k in controller) {
            if (controller.hasOwnProperty(k) && typeof controller[k] === "function") {
                actions[k] = getInterceptor(k)
            }
        }
        if (options?.initController) {
            options.initController({controller,data,setData,setLoading,actions,isMounted})
        }
        return actions
    },[controller])

    return {data,setData,actions,loading}
}
