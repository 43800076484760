import React, {useState} from 'react';
import {useAuth} from "../login/auth.hook";
import {LoginView} from "../login/LoginView";
import {useController} from "../mvc/mvc.hook";
import {useNotifications} from "../notifications/notifications.hook";
import {BACKEND_BASE_URL} from "../api";

export const Login = () => {
    const {login} = useAuth()
    const [error,setError] = useState(null)
    const {clearAllNotifications, addNotification} = useNotifications()
    const {actions,loading} = useController(() => ({
        async onLogin(username,password) {
            try {
                const res = await (fetch(BACKEND_BASE_URL+'/admin/auth/login', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({username, password})
                }).catch(err => {
                    console.error(err)
                    throw new Error("Couldn't connect to server")
                }))
                if (res.status < 300) {
                    const body = await res.json()
                    clearAllNotifications()
                    addNotification({type: 'success', message: 'Logged In'})
                    login(body.access_token)
                } else {
                    if (res.status === 401) {
                        throw new Error('Invalid Credentials')
                    } else {
                        throw new Error(`Invalid server response: ${res.status}, ${res.statusText}`)
                    }
                }
            } catch (e) {
                console.error(e)
                setError(e.message)
            }

        }
    }),{asyncActions: true},[login])
    return <LoginView {...{actions,loading,error}} />
};
