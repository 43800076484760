import {Menu} from "antd";
import React from "react";

export const NavBar = ({items,onMenuItemSelected,selected}) => {
    return (<><div className="logo" />
        <Menu theme="dark" defaultSelectedKeys={[selected]} mode="inline">
            {items.map((item) =>
                (!item.sub) ?
                    <Menu.Item key={item.key} onClick={onMenuItemSelected && (() => {onMenuItemSelected(item)})} icon={item.icon}>
                        {item.title}
                    </Menu.Item>
                    :
                    <Menu.SubMenu key={item.key} icon={item.icon} title="User">
                        {item.sub.map((subitem) =>
                            <Menu.Item key={subitem.key} onClick={onMenuItemSelected && (() => {onMenuItemSelected(subitem)})} icon={subitem.icon}>
                                {subitem.title}
                            </Menu.Item>
                        )}
                    </Menu.SubMenu>
            )}
        </Menu></>)
}

/*export const NavBar = ({onMenuItemSelected}) => (<><div className="logo" />
    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
        <Menu.Item onClick={onMenuItemSelected} key="1" icon={<PieChartOutlined />}>
            Option 1
        </Menu.Item>
        <Menu.Item onClick={onMenuItemSelected} key="2" icon={<DesktopOutlined />}>
            Option 2
        </Menu.Item>
        <Menu.SubMenu key="sub1" icon={<UserOutlined />} title="User">
            <Menu.Item onClick={onMenuItemSelected} key="3">Tom</Menu.Item>
            <Menu.Item onClick={onMenuItemSelected} key="4">Bill</Menu.Item>
            <Menu.Item onClick={onMenuItemSelected} key="5">Alex</Menu.Item>
        </Menu.SubMenu>
        <Menu.SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
            <Menu.Item onClick={onMenuItemSelected} key="6">Team 1</Menu.Item>
            <Menu.Item onClick={onMenuItemSelected} key="8">Team 2</Menu.Item>
        </Menu.SubMenu>
        <Menu.Item onClick={onMenuItemSelected} key="9" icon={<FileOutlined />}>
            Files
        </Menu.Item>
    </Menu></>)*/
