import {AutoComplete, Form, Tag} from "antd";
import {useState} from "react";

function truncate(value,size=50) {
    if (value.length >= size) {
        return value.substr(0,size)+"..."
    } else {
        return value
    }
}

export const EditInterest = ({label,addInterest,findInterest,removeInterest,userId,data,actions,addNewItems,titleField='title'}) => {
    const [options,setOptions] = useState(null)
    const [selected,setSelected] = useState(null)
    function onSelect(value) {
        console.log(value)
        const selected = options.filter(v => v.value===value)[0].details
        addInterest(userId,selected)
        clearSelected()
    }
    async function onSearch(name) {
        const result = await findInterest(name)
        const options = result.map(i => ({label: <div>{i[titleField]}</div>, value: i.name, details: i}))
        if (addNewItems) {
            if (!options.filter(i => i.details[titleField] === name).length) {
                options.unshift({label: <div>{name}</div>, value: name, details:{name,[titleField]:name}})
            }
        }
        setOptions(options)
    }
    function clearSelected() {
        setSelected(null)
    }
    function _removeInterest(name) {
        removeInterest(userId,name)
    }
    return <div>
        <Form.Item label={label}>
            <AutoComplete
                options={options}
                style={{ width: 200 }}
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={value => setSelected(value)}
                value={selected}
                placeholder={"find "+label}
            />
            <div style={{margin: "10px 0"}}>
                {data && data.map(e => <Tag key={e.name} closable onClose={() => _removeInterest(e.name)}>{truncate(e[titleField],50)}</Tag>)}
            </div>
        </Form.Item>
    </div>
}
