import {Button, Form} from "antd";
import React from "react";
import {LeftOutlined} from "@ant-design/icons";

const columns = [
    { dataIndex: 'title', key: 'title' },
    { dataIndex: 'value', key: 'value' },
]

export const CrudCreateItemView = ({actions,fields,loading,name}) => {
    const [form] = Form.useForm()
    const thisFields = fields.filter(f => f.create)
    const onFinish = (values) => {
        actions.save(values)
    }
    return (
        <div>
            <div style={{display: "flex", alignItems: "baseline", marginBottom: "20px"}}>
                <Button icon={<LeftOutlined />} onClick={actions.goToList} />
                <h1 style={{margin: "0 10px"}}>Create {name}</h1>
            </div>
            <Form form={form} layout={'vertical'} onFinish={onFinish}>
                {thisFields.map(f => f.createRender(f))}
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>Save</Button>
                </Form.Item>
            </Form>
        </div>
    );
};
