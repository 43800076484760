import {Button, Form, Input, Space, Table} from "antd";
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined} from "@ant-design/icons";
import React, {useEffect, useMemo, useState} from "react";

let lastPagination = {};

export const CrudListView = ({data,fields,actions,loading,tableProps}) => {

    const columns = [
        ...(fields.filter(field => field.list===undefined || field.list).map(({key,title,listRender}) => ({key,title,dataIndex:key,render:listRender}))),
        { title: 'Actions', key: 'actions', render: (text, record, idx)=> {
                return <Space>
                    {actions.view && <Button icon={<EyeOutlined />} onClick={() => actions.view(record,idx)} >View</Button>}
                    {actions.delete && <Button danger={true} icon={<DeleteOutlined />} loading={record.deleteInProgress} onClick={(ev) => {
                        actions.delete(record,idx)
                    }} >Delete</Button>}
                    {actions.modify && <Button icon={<EditOutlined />} onClick={(ev) => actions.modify(record)}>Modify</Button>}
                </Space>
            } }
    ]

    const keyField = useMemo(() => fields.filter(f => f.keyField)[0],[fields])

    const tableData = useMemo(() => data && data.map(row => ({...row, key:row[keyField && keyField.key]})),[data,keyField])

    const onSearch = (query) => {
        actions.search(query)
    }

    const [pagination,setPagination] = useState(null)

    useEffect(() => {
        actions.loadInitialData().then(r => null)
    },[])

    useEffect(() => {
        if (lastPagination[window.location.pathname]) {
            setPagination(lastPagination[window.location.pathname])
        }
    },[])

    const onChange = (pagination) => {
        lastPagination[window.location.pathname] = pagination
        setPagination(pagination)
    }

    return (
        <div>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                {actions.create && <div style={{marginBottom: "15px"}}><Button icon={<PlusOutlined />} onClick={(ev) => actions.create()}>Create</Button></div>}
                <Form layout={"inline"} style={{marginLeft: "auto", marginBottom: "15px"}} onFinish={(values) => {
                    onSearch(values.search);
                }} >
                    <Form.Item name={"search"}>
                        <Input type={"text"} />
                    </Form.Item>
                    <Form.Item>
                        <Button type={"primary"} htmlType={"submit"}>Search</Button>
                    </Form.Item>
                </Form>
            </div>
            <div style={{overflowX: "auto"}}>
                <Table loading={!!loading?.loadInitialData} dataSource={tableData} columns={columns} onChange={onChange} pagination={pagination} {...tableProps} />
            </div>
        </div>
    );
};
