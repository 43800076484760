import {map, mount, redirect, route, withView} from "navi";
import {Home} from "./pages";
import {prototypeRoutes} from "./prototype/prototype.routes";
import {Users} from "./pages/users";
import {ViewUser} from "./pages/users/view";
import {AdminLayout} from "./layout/AdminLayout";
import React from "react";
import {Nav} from "./Nav";
import {Login} from "./pages/login";
import {LoginLayout} from "./layout/LoginLayout";
import {CreateUser} from "./pages/users/create";
import {ModifyUser} from "./pages/users/modify";
import {AdminUsers} from "./pages/admin-users";
import {ViewAdminUser} from "./pages/admin-users/view";
import {CreateAdminUser} from "./pages/admin-users/create";
import {ModifyAdminUser} from "./pages/admin-users/modify";


const AdminLayoutWithNav = (props) => <AdminLayout {...props} navbar={<Nav/>}/>

export const loggedInRoutes = {
    '/': route({
        title: 'Home',
        view: <Home/>
    }),
    '/login': redirect('/'),
    '/users': route({
        title: 'Users',
        view: Users,
        getData: () => ({
            base: '/users'
        })
    }),
    '/users/create': route({
        title: 'Create User',
        view: CreateUser,
        getData: () => ({
            base: '/users'
        })
    }),
    '/users/view/:id': route((request) => ({
        title: 'View ' + request.params.id,
        view: <ViewUser id={request.params.id} base={'/users'}/>
    })),
    '/users/modify/:id': route((request) => ({
        title: 'Modify ' + request.params.id,
        view: <ModifyUser id={request.params.id} base={'/users'}/>
    })),
    '/prototype': prototypeRoutes,
    '/admin-users': route({
        title: 'Admin Users',
        view: AdminUsers,
        getData: () => ({
            base: '/admin-users'
        })
    }),
    '/admin-users/view/:id': route((request) => ({
        title: 'View ' + request.params.id,
        view: <ViewAdminUser id={request.params.id} base={'/admin-users'}/>
    })),
    '/admin-users/create': route({
        title: 'Create Admin User',
        view: CreateAdminUser,
        getData: () => ({
            base: '/admin-users'
        })
    }),
    '/admin-users/modify/:id': route((request) => ({
        title: 'Modify ' + request.params.id,
        view: <ModifyAdminUser id={request.params.id} base={'/admin-users'}/>
    })),
}

export const loggedOutRoutes = {
    '/login': withView(() => <LoginLayout />,route({
        title: 'Login',
        view: Login
    })),
    '/prototype': prototypeRoutes
}

for (const r in loggedInRoutes) {
    if (loggedInRoutes.hasOwnProperty(r) && typeof loggedOutRoutes[r] === "undefined") {
        loggedOutRoutes[r] = redirect("/login")
    }
}

export const routes = map(
    (request,context) => {
        console.log(request)
        const routes = context.isLoggedIn ? loggedInRoutes : loggedOutRoutes
        if (request.path.startsWith('/login') || request.path.startsWith('/prototype')) {
            return mount(routes)
        } else {
            return withView(() => AdminLayoutWithNav,mount(routes))
        }
    }
)
