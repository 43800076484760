
export class ErrorBase extends Error {

    type

    constructor(message, messageType = 'error') {
        super(message);
        this.messageType = messageType
    }

}