import {EditInterest} from "./EditInterest";

const interest = "movies"
const interestTitle = "Movies"

export const Movies = ({userId,data,actions}) => {

    return <EditInterest
        {...{userId,actions,data}}
        label={interestTitle}
        addInterest={(userId,item) => actions.addInterest(interest,userId,item)}
        findInterest={(name) => actions.findInterest(interest,name)}
        removeInterest={(userId,name) => actions.removeInterest(interest,userId,name)}
    />

}
