import {Button, Table} from "antd";
import React, {useEffect} from "react";
import {LeftOutlined} from "@ant-design/icons";

const columns = [
    { dataIndex: 'title', key: 'title' },
    { dataIndex: 'value', key: 'value' },
]

export const CrudViewItemView = ({actions,data,fields,nameKey,loading}) => {
    const contents = data && fields.filter(f => f.view === undefined || f.view)
        .map((f) => {
            const {key,title,viewRender} = f
            return ({ key, title, value: viewRender?viewRender(data,f):(data && data[key]) })
        })
    useEffect(() => {
        actions.loadInitialData().then(r => null)
    },[])
    return (
        <div>
            <div style={{display: "flex", alignItems: "baseline", marginBottom: "20px"}}>
                <Button icon={<LeftOutlined />} onClick={actions.goToList} />
                <h1 style={{margin: "0 10px"}}>View {data && nameKey && data[nameKey]}</h1>
            </div>
            <Table loading={loading} dataSource={contents} columns={columns} pagination={false} components={{header:{row:()=>null}}} />
        </div>
    );
};
