import React, {Suspense} from 'react';
import {Router, useNavigation, View} from "react-navi";
import {routes} from "./routes";
import {useAuth, withAuthProvider} from "./login/auth.hook";
import {withNotificationsProvider} from "./notifications/notifications.hook";


const Login = (props) => {
    const {isLoggedIn} = useAuth()
    let navigation = useNavigation()
    /*useEffect(() => {
        console.log("logged in changed:",isLoggedIn)
        if (!isLoggedIn) {
            navigation.navigate('/login')
        }
    },[isLoggedIn,navigation])*/
    return props.children
}

function _App() {
    const {isLoggedIn} = useAuth()
    return (
        <Router routes={routes} context={{isLoggedIn}}>
            <Suspense fallback={null}>
                <Login>
                    <View/>
                </Login>
            </Suspense>
        </Router>
    );
}

export const App = withNotificationsProvider(withAuthProvider(_App));
