import {Form, Input} from "antd";
import React from "react";

export const adminUserFields = [
    { key: '_id',    title: 'Id',    list: true, view: true, keyField: true },
    { key: 'username', title: 'Username', list: true, view: true, create: true, modify: false,
        createRender: (f) => (<Form.Item key={f.key} name={f.key} label={f.title}><Input/></Form.Item>),
        modifyRender: (data,f) => (<Form.Item key={f.key} name={f.key} label={f.title} initialValue={data[f.key]}><Input/></Form.Item>)
    },
    { key: 'password', title: 'Password', list: false, view: false, create: true, modify: true,
        createRender: (f) => (<Form.Item key={f.key} name={f.key} label={f.title}><Input type={"password"}/></Form.Item>),
        modifyRender: (data,f) => (<Form.Item key={f.key} name={f.key} label={f.title} initialValue={data[f.key]}><Input type={"password"}/></Form.Item>)
    },
]
