import React from 'react';
import {userFields} from "../../../modules/users/user.fields";
import {useController} from "../../../mvc/mvc.hook";
import {MockUserModel} from "../../users/mock-user.model";
import {useCurrentRoute, useNavigation} from "react-navi";
import {CrudListView} from "../../../crud/view/CrudListView";
import {UserController} from "../../../modules/users/user.controller";
import {useErrorHandler} from "../../../errors/error-handler";

export const Users = () => {
    const route = useCurrentRoute();
    const navigation = useNavigation()
    const errorHandler = useErrorHandler();
    const {actions,loading,data} = useController(
        ({setData}) => {
            const model = MockUserModel
            return new UserController(model, navigation, route.data.base, errorHandler,false,setData)
        },
        {asyncActions:true,stateless:false,logging:true},
        [navigation,route,errorHandler]
    )
    return <CrudListView fields={userFields} {...{data,actions,loading}} />
}
