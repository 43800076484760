import {useCurrentRoute, useNavigation} from "react-navi";
import {useNav} from "./nav.hook";
import {NavBar} from "./layout/NavBar";
import React from "react";

export function Nav() {
    let navigation = useNavigation()
    let currentRoute = useCurrentRoute();
    let menuItems = useNav();
    function onMenuItemSelected(menuItem) {
        if (menuItem.path) {
            navigation.navigate(menuItem.path)
        }
        if (menuItem.onClick) {
            menuItem.onClick()
        }
    }
    const found = menuItems.find(item =>
        item.path === currentRoute.url.href ||
        (item.sub && item.sub.find(subitem =>
            subitem.path === currentRoute.url.href
        ))
    )
    return <NavBar items={menuItems} onMenuItemSelected={onMenuItemSelected} selected={found?.key} />
}
