import React from 'react';
import {View} from "react-navi";
import {Alert, Layout} from "antd";
import {useNotifications} from "../notifications/notifications.hook";

const {Content} = Layout

export const LoginLayout = () => {
    const {notifications,removeNotification} = useNotifications()
    return (
        <Layout style={{ minHeight: '100vh' }}>
            {!!notifications && !!notifications.length && <div style={{padding: "10px", width: "100%", maxWidth: "900px", margin: "0 auto"}}>
                {notifications.map(m => <Alert message={m.message} type={m.type} showIcon closable onClose={() => setTimeout(() => removeNotification(m),300)} />)}
            </div>}
            <Content style={{display: "flex", flexDirection: "column"}}>
                <div style={{flex:"1"}} />
                <div>
                    <div style={{
                        maxWidth: '380px',
                        margin: '20px auto'
                    }}>
                        <View />
                    </div>
                </div>
                <div style={{flex:"3"}} />
            </Content>
        </Layout>
    );
};