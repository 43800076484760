import React from "react"

import {Breadcrumb, Drawer, Layout, Menu} from 'antd';
import {
    DesktopOutlined,
    FileOutlined,
    MenuFoldOutlined,
    MenuOutlined,
    MenuUnfoldOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
} from '@ant-design/icons';
import {View} from "react-navi";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const NavBar = ({onMenuItemSelected}) => (<><div className="logo" />
<Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
    <Menu.Item onClick={onMenuItemSelected} key="1" icon={<PieChartOutlined />}>
        Option 1
    </Menu.Item>
    <Menu.Item onClick={onMenuItemSelected} key="2" icon={<DesktopOutlined />}>
        Option 2
    </Menu.Item>
    <SubMenu key="sub1" icon={<UserOutlined />} title="User">
        <Menu.Item onClick={onMenuItemSelected} key="3">Tom</Menu.Item>
        <Menu.Item onClick={onMenuItemSelected} key="4">Bill</Menu.Item>
        <Menu.Item onClick={onMenuItemSelected} key="5">Alex</Menu.Item>
    </SubMenu>
    <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
        <Menu.Item onClick={onMenuItemSelected} key="6">Team 1</Menu.Item>
        <Menu.Item onClick={onMenuItemSelected} key="8">Team 2</Menu.Item>
    </SubMenu>
    <Menu.Item onClick={onMenuItemSelected} key="9" icon={<FileOutlined />}>
        Files
    </Menu.Item>
</Menu></>)

export class Layout1 extends React.Component {

    state = {
        collapsed: false,
        mobileMenuShown: false,
    };

    setMobileMenuShown(mobileMenuShown) {
        this.setState({
            mobileMenuShown
        })
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    render() {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Drawer
                    placement="left"
                    onClose={() => this.setMobileMenuShown(false)}
                    closable={false}
                    visible={this.state.mobileMenuShown}
                    className="hideOnDesktop"
                    drawerStyle={{
                        "background": "#001529"
                    }}
                    bodyStyle={{
                        "padding": "0"
                    }}
                >
                    <NavBar onMenuItemSelected={() => this.setMobileMenuShown(false)} />
                </Drawer>
                <Sider
                       trigger={null}
                       collapsedWidth="0"
                       collapsed={this.state.collapsed}
                       onCollapse={this.onCollapse}
                       className="hideOnMobile"
                >
                    <NavBar />
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger hideOnMobile',
                            onClick: this.toggle,
                        })}
                        <MenuOutlined
                            className='trigger hideOnDesktop'
                            onClick={() => this.setMobileMenuShown(true)}
                        />
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                            <Breadcrumb.Item>User</Breadcrumb.Item>
                            <Breadcrumb.Item>Bill</Breadcrumb.Item>
                        </Breadcrumb>
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <View />
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
                </Layout>
            </Layout>
        );
    }
}