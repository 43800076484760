import {useNotifications} from "../notifications/notifications.hook";
import {useMemo} from "react";

export const errorHandler = (addNotification) => (error) => {
    console.log(error.messageType,error.message)
    addNotification({type: error.messageType || 'error', message: error.message})
}

export const useErrorHandler = () => {
    const {addNotification} = useNotifications()
    return useMemo(() => errorHandler(addNotification),[addNotification])
}