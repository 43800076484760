import React from 'react';
import {userFields} from "../../../modules/users/user.fields";
import {useController} from "../../../mvc/mvc.hook";
import {MockUserModel} from "../../users/mock-user.model";
import {useNavigation} from "react-navi";
import {UserController} from "../../../modules/users/user.controller";
import {useErrorHandler} from "../../../errors/error-handler";
import {CrudModifyItemView} from "../../../crud/view/CrudModifyItemView";

export const ModifyUser = ({id,base}) => {
    const navigation = useNavigation()
    const errorHandler = useErrorHandler();

    const {data,actions,loading} = useController(
        ({setData}) => {
            const model = MockUserModel
            return new UserController(model,navigation,base,errorHandler,id,setData)
        },
        {
            asyncActions: true
        },
        [navigation]
    )
    return <CrudModifyItemView {...{data,actions,loading}} fields={userFields} name={'User'} base={base} />
}
