import {Button, Form, Spin} from "antd";
import React, {useEffect} from "react";
import {LeftOutlined} from "@ant-design/icons";

const columns = [
    { dataIndex: 'title', key: 'title' },
    { dataIndex: 'value', key: 'value' },
]

export const CrudModifyItemView = ({data,actions,fields,loading,name,base}) => {
    const [form] = Form.useForm()
    const thisFields = fields.filter(f => f.modify)
    const onFinish = (values) => {
        actions.save({_id:data._id,...values})
    }
    useEffect(() => {
        actions.loadInitialData().then(r => null)
    },[])
    useEffect(()=>{
        form.setFieldsValue({...data})
    },[data])
    return (
        <Spin spinning={!data}>
            {data && <>
                <div style={{display: "flex", alignItems: "baseline", marginBottom: "20px"}}>
                    <Button icon={<LeftOutlined />} onClick={actions.goToList} />
                    <h1 style={{margin: "0 10px"}}>Modify {name}</h1>
                </div>
                <Form form={form} layout={'vertical'} onFinish={onFinish}>
                    {thisFields.map(f => f.modifyRender(data,f,{base,model:null,actions}))}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>Save</Button>
                    </Form.Item>
                </Form>
            </>}
        </Spin>
    );
};
