import {mount, route, withView} from "navi";
import {Layout1} from "./layout/Layout1";
import {Users} from "./pages/users";
import {ViewUser} from "./pages/users/view";
import {Login} from "./pages/Login";
import {LoginLayout} from "../layout/LoginLayout";
import {CreateUser} from "./pages/users/create";
import {ModifyUser} from "./pages/users/modify";

export const prototypeRoutes = mount({
    '/layout1': withView(<Layout1 />,route({
        title: 'Layout1',
        view: <div>This is a cat</div>
    })),
    '/login': withView(LoginLayout,route({
       title: 'Login Prototype',
       view: Login
    })),
    '/users': withView(<Layout1 />,route({
        title: 'Prototype - Users',
        view: Users,
        getData: () => ({
            base: '/prototype/users'
        })
    })),
    '/users/view/:id': withView(<Layout1 />,route((request) => {
        console.log("route request:",request);
        return {
            title: 'Prototype - View 1',
            view: <ViewUser id={request.params.id} base={'/prototype/users'} />
        }})),
    '/users/modify/:id': withView(<Layout1 />,route((request) => {
        console.log("route request:",request);
        return {
            title: 'Prototype - Modify 1',
            view: <ModifyUser id={request.params.id} base={'/prototype/users'} />
        }})),
    '/users/create': withView(<Layout1 />,route((request) => {
        console.log("route request:",request);
        return {
            title: 'Prototype - Create',
            view: <CreateUser base={'/prototype/users'} />
        }}))
})