import React from 'react';
import {userFields} from "../../modules/users/user.fields";
import {useController} from "../../mvc/mvc.hook";
import {useCurrentRoute, useNavigation} from "react-navi";
import {UserController} from "../../modules/users/user.controller";
import {useErrorHandler} from "../../errors/error-handler";
import {CrudCreateItemView} from "../../crud/view/CrudCreateItemView";
import {UserModel} from "../../modules/users/user.model";
import {BACKEND_BASE_URL} from "../../api";
import {useAuth} from "../../login/auth.hook";
import {AdminUserController} from "../../modules/admin-users/admin-user.controller";
import {AdminUserModel} from "../../modules/admin-users/admin-user.model";
import {adminUserFields} from "../../modules/admin-users/admin-user.fields";

export const CreateAdminUser = () => {
    const route = useCurrentRoute();
    const auth = useAuth();
    const navigation = useNavigation()
    const errorHandler = useErrorHandler();
    const {actions,loading} = useController(
        () => {
            const model = new AdminUserModel(BACKEND_BASE_URL + "/admin",auth)
            return new AdminUserController(model,navigation,route.data.base,errorHandler,false)
        },
        {
            asyncActions: true,
        })
    return <CrudCreateItemView {...{actions,loading}} fields={adminUserFields} name={'Admin User'} />
}
