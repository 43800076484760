import {LogoutOutlined, PieChartOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";
import {useAuth} from "./login/auth.hook";
import {useNotifications} from "./notifications/notifications.hook";

export const useNav = () => {
    const {logout} = useAuth()
    const {addNotification, clearNotifications} = useNotifications()
    return [
        {
            key: "home",
            title: "Home",
            path: '/',
            icon: <PieChartOutlined />
        },
        {
            key: "users",
            title: "Users",
            path: '/users',
            icon: <UserOutlined />
        },
        {
            key: "admin-users",
            title: "Admin Users",
            path: '/admin-users',
            icon: <UserOutlined />
        },
        /*
        {
            key: "parent",
            title: "Parent",
            icon: <UserOutlined />,
            sub: [
                {
                    key: "tom",
                    title: "Tom"
                },
                {
                    key: "bill",
                    title: "Bill"
                }
            ]
        },*/
        {
            title: "Logout",
            icon: <LogoutOutlined />,
            onClick: () => {
                logout()
                clearNotifications()
                addNotification({type: 'warning', message: 'Logged Out'})
            }
        }
    ]
}
