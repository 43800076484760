import React, {useMemo} from 'react';
import {useController} from "../mvc/mvc.hook";
import {UserModel} from "../modules/users/user.model";
import {useAuth} from "../login/auth.hook";
import {API_BASE} from "../api";
import {Table} from "antd";

class DashboardController {

    data = null

    constructor(userModel,setData) {
        this.userModel = userModel
        this.setData = setData
    }

    init = async (self) => {
        console.log("init controller")
        this.self = self
        try {
            const data = await this.userModel.getDashboard()
            this.setData(data)
        } catch (e) {
            console.error(e)
        }
    }

}

const columns = [
    { dataIndex: 'title', key: 'title' },
    { dataIndex: 'value', key: 'value', render: (v) => <strong>{v}</strong> },
]

export function Home() {

    const auth = useAuth()

    const {data,loading} = useController(({setData}) => {
        return new DashboardController(new UserModel(API_BASE,auth),setData)
    },{
        asyncActions: true,
        initController: ({controller,actions}) => actions.init()
    })

    const contents = useMemo(() => {
        return data && [
            {title: "Total Men", value: data.totalMen},
            {title: "Total Women", value: data.totalWomen},
            {title: "Reg. last 24hrs", value: data.regDay},
            {title: "Reg. 7 days", value: data.regWeek},
            {title: "Reg. 30 days", value: data.regThirtyDays},
            {title: "Reg. all the time", value: data.regAllTheTime},
        ]
    },[data])

    return (
        <div>
            <div style={{display: "flex", alignItems: "baseline", marginBottom: "20px"}}>
                <h1 style={{margin: "0 10px"}}>Dashboard</h1>
            </div>
            <Table loading={loading} dataSource={contents} columns={columns} pagination={false} components={{header:{row:()=>null}}} />
        </div>
    );

}
