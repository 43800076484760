import {ModelBase} from "../../crud/model/model-base";
import {HttpRequestError} from "../../errors/HttpRequestError";
import {AppError} from "../../errors/AppError";

export class AdminUserModel extends ModelBase {
    getAll = async () => {
        return await this.request({path: "/admin-users"})
    }
    searchItem = async (query) => {
        return await this.request({path:"/admin-users?query="+encodeURIComponent(query)})
    }
    getItem = async (id) => {
        return await this.request({ path: "/admin-users/"+id })
    }
    create = async (item) => {
        try {
            return await this.request({method: "POST", path: "/admin-users/", body: item})
        } catch (e) {
            if (e instanceof HttpRequestError && e.status === 406) {
                throw new AppError("Username not available")
            } else {
                throw e
            }
        }
    }
    delete = async (id) => {
        return await this.request({method: "DELETE", path: "/admin-users/"+id})
    }
    modify = async (item) => {
        return await this.request({method: "PUT", path: "/admin-users/" + item._id, body: item})
    }
}
