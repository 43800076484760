
export class CommonCrudController {

    data = []
    updateCallback = ()=>{}
    itemId = false
    errorHandler = (e) => {
        console.error(e)
    }

    constructor(model,navigation,base,errorHandler = null,itemId=false,setData) {
        console.log("controller base:",base)
        this.model = model;
        this.navigation = navigation;
        this.base = base;
        this.itemId = itemId;
        if (errorHandler) this.errorHandler = errorHandler;
        this.updateCallback = (data) => {
            if (data === undefined) data = this.data;
            if (typeof data === "object") {
                if (Array.isArray(data)) {
                    setData([...data])
                } else {
                    setData({...data})
                }
            }
        }
    }

    getModel = () => {
        return this.model
    }

    reloadData = async () => {
        if (typeof this.itemId === "number" || typeof this.itemId === "string") {
            this.data = await this.getItem(this.itemId)
        } else {
            this.data = await this.getAll()
        }
        this.updateCallback()
    }

    loadInitialData = async () => {
        await this.reloadData();
    }

    create = () => {
        console.log("create item")
        this.navigation && this.base && this.navigation.navigate(this.base+"/create")
    }

    goToList = () => {
        this.navigation && this.base && this.navigation.navigate(this.base)
    }

    async save(item) {
        console.log("save item")
        try {
            if (item._id) {
                console.log("modify")
                const savedItem = await this.model.modify(item)
                console.log("savedItem:", savedItem)
                this.updateCallback(savedItem)
            } else {
                console.log("create")
                const savedItem = await this.model.create(item)
                return savedItem;
            }
        } catch (e) {
            this.errorHandler(e)
            return false
        }

    }

    modify = (item) => {
        console.log("modify",this.navigation,this.base)
        this.navigation && this.base && this.navigation.navigate(this.base+'/modify/'+item._id)
    }

    view = (item) => {
        console.log('view',item)
        this.navigation && this.base && this.navigation.navigate(this.base+'/view/'+item._id)
    }

    getAll = async () => {
        try {
            return await this.model.getAll()
        } catch (e) {
            this.errorHandler(e)
        }
    }

    getItem = async (id) => {
        try {
            return await this.model.getItem(id)
        } catch (e) {
            this.errorHandler(e)
        }
    }

    delete = async (item) => {

        const idx = this.data.findIndex(i => i._id === item._id);
        if (idx < 0) {
            console.log("index not found: ",this.data)
            return;
        }
        this.data[idx].deleteInProgress = true
        this.updateCallback()
        try {
            const response = await this.model.delete(item._id)
            if (response) {
                await this.reloadData()
            } else {
                this.data[idx].deleteInProgress = false
                this.updateCallback()
            }
        } catch (e) {
            this.errorHandler(e)
        }

    }

}
