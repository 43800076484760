import {CommonCrudController} from "../../crud/controller/common-crud.controller";

export class AdminUserController extends CommonCrudController {
    search = async (query) => {
        try {
            this.data = await this.model.searchItem(query)
            this.updateCallback()
        } catch (e) {
            this.errorHandler(e)
        }
    }
    save = async (item) => {
        const savedItem = await super.save(item)
        if (savedItem && !item._id) {
            this.goToList()
        }
    }
}
