import {useController} from "../../mvc/mvc.hook";
import {userFields} from "../../modules/users/user.fields";
import React from "react";
import {UserModel} from "../../modules/users/user.model";
import {useNavigation} from "react-navi";
import {useAuth} from "../../login/auth.hook";
import {CrudViewItemView} from "../../crud/view/CrudViewItemView";
import {UserController} from "../../modules/users/user.controller";
import {useErrorHandler} from "../../errors/error-handler";
import {BACKEND_BASE_URL} from "../../api";
import {AdminUserModel} from "../../modules/admin-users/admin-user.model";
import {AdminUserController} from "../../modules/admin-users/admin-user.controller";
import {adminUserFields} from "../../modules/admin-users/admin-user.fields";

export const ViewAdminUser = ({id,base}) => {
    const navigation = useNavigation()
    const auth = useAuth();
    const errorHandler = useErrorHandler();
    const {data,actions,loading} = useController(
        ({setData}) => {
            const model = new AdminUserModel(BACKEND_BASE_URL + "/admin", auth)
            return new AdminUserController(model,navigation,base,errorHandler,id,setData)
        },
        {})
    return <CrudViewItemView {...{data,actions,loading}} fields={adminUserFields} nameKey={'name'} />
}
