import React from 'react';
import {userFields} from "../../../modules/users/user.fields";
import {useController} from "../../../mvc/mvc.hook";
import {MockUserModel} from "../../users/mock-user.model";
import {useNavigation} from "react-navi";
import {UserController} from "../../../modules/users/user.controller";
import {useErrorHandler} from "../../../errors/error-handler";
import {CrudCreateItemView} from "../../../crud/view/CrudCreateItemView";

export const CreateUser = ({base}) => {
    const navigation = useNavigation()
    const errorHandler = useErrorHandler();
    const {actions,loading} = useController(
        () => {
            const model = MockUserModel
            return new UserController(model,navigation,base,errorHandler,false)
        },
        {
            asyncActions: true,
        })
    return <CrudCreateItemView {...{actions,loading}} fields={userFields} name={'User'} />
}
