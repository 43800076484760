import {useAuth} from "../../login/auth.hook";
import {BACKEND_BASE_URL} from "../../api";
import {Button, Checkbox, Form, Input, Upload} from "antd";
import React from "react";
import {UploadOutlined} from "@ant-design/icons";
import {Books} from "./components/Books";
import {Movies} from "./components/Movies";
import {Sports} from "./components/Sports";
import {Music} from "./components/Music";

const UserImage = ({data}) => {
    const {accessToken} = useAuth()
    return (
        <div style={{marginBottom: "7px"}} className="profile-image-container">
            <img className="profile-image" src={BACKEND_BASE_URL + "/admin/users/profile-image/"+data._id+"/"+data.profileImage+"?token="+accessToken} alt="Profile" />
        </div>
    )
}

const ThumbnailImage = ({data}) => {
    const {accessToken} = useAuth()
    return (
        <div className="profile-image-container profile-image-container--thumbnial">
            <img className="profile-image profile-image--thumbnail" src={BACKEND_BASE_URL + "/admin/users/profile-image/"+data._id+"/"+data.profileImage+"?token="+accessToken+"&thumbnail=1"} alt="Profile" />
        </div>
    )
}

export const userFields = [
    { key: 'profileImage', title: 'Profile', list: true,
        listRender: (image,data) => {
            return <ThumbnailImage data={data}/>
        }
    },
    { key: '_id',    title: 'Id',    list: true, view: true, keyField: true },
    { key: 'name', title: 'Name', list: true, view: true, create: true, modify: true,
        createRender: (f) => (<Form.Item key={f.key} name={f.key} label={f.title}><Input/></Form.Item>),
        modifyRender: (data,f) => (<Form.Item key={f.key} name={f.key} label={f.title} initialValue={data[f.key]}><Input/></Form.Item>)
    },
    { key: 'email', title: 'Email', list: false, view: true, create: false, modify: true,
        createRender: (f) => (<Form.Item key={f.key} name={f.key} label={f.title}><Input type={"email"}/></Form.Item>),
        modifyRender: (data,f) => (<Form.Item key={f.key} name={f.key} label={f.title} initialValue={data[f.key]}><Input type={"email"}/></Form.Item>)
    },
    { key: 'gender', title: 'Gender', list: true, view: true, create: false, modify: false },
    { key: 'banned', title: 'Banned', list: false, view: true, create: false, modify: true,
        modifyRender: (data,f) => (
            <Form.Item key={f.key} name={f.key} valuePropName={"checked"} initialValue={data[f.key]}>
                <Checkbox>{f.title}</Checkbox>
            </Form.Item>
        )
    },
    { key: 'swipeCredits', title: 'Swipe Credits', list: false, view: true, create: false, modify: true,
        modifyRender: (data,f) => (<Form.Item key={f.key} name={f.key} label={f.title} initialValue={data[f.key]}><Input/></Form.Item>)
    },
    { key: 'books', title: 'Books', list: false, view: true, create: false, modify: true,
        viewRender: (data,f) => (<div>{JSON.stringify(data[f.key])}</div>),
        modifyRender: (data,f,{model,actions}) => (<><Books {...{userId:data._id,data:data[f.key],actions}} /></>)
    },
    { key: 'movies', title: 'Movies', list: false, view: true, create: false, modify: true,
        viewRender: (data,f) => (<div>{JSON.stringify(data[f.key])}</div>),
        modifyRender: (data,f,{model,actions}) => (<><Movies {...{userId:data._id,data:data[f.key],actions}} /></>)
    },
    { key: 'sports', title: 'Sports', list: false, view: true, create: false, modify: true,
        viewRender: (data,f) => (<div>{JSON.stringify(data[f.key])}</div>),
        modifyRender: (data,f,{model,actions}) => (<><Sports {...{userId:data._id,data:data[f.key],actions}} /></>)
    },
    { key: 'music', title: 'Music', list: false, view: true, create: false, modify: true,
        viewRender: (data,f) => (<div>{JSON.stringify(data[f.key])}</div>),
        modifyRender: (data,f,{model,actions}) => (<><Music {...{userId:data._id,data:data[f.key],actions}} /></>)
    },
    { key: 'profileImage', title: 'Profile Image', list: false, view: true, modify: true,
        viewRender: (data,f) => <UserImage data={data} />,
        modifyRender: (data,f,{model,actions}) => {
            console.log(actions)
            model = actions.getModel()
            return <div style={{marginBottom: "20px"}}>
                <UserImage data={data}/>
                <Upload
                    name={"file"}
                    action={model.getApiBase()+"/users/profile-image/" + data._id}
                    headers={model.getHeaders("POST",true)}
                    onChange={(info) => {
                        if (info.file?.status === "done") {
                            actions.uploadFinished()
                        }
                    }}>
                    <Button icon={<UploadOutlined/>}>Click to Upload</Button>
                </Upload>
            </div>;
        }
    }
]
