import {useCurrentRoute, useNavigation} from "react-navi";
import {useAuth} from "../../login/auth.hook";
import {useErrorHandler} from "../../errors/error-handler";
import {useController} from "../../mvc/mvc.hook";
import {UserModel} from "../../modules/users/user.model";
import {BACKEND_BASE_URL} from "../../api";
import {UserController} from "../../modules/users/user.controller";
import {CrudListView} from "../../crud/view/CrudListView";
import {userFields} from "../../modules/users/user.fields";
import React from "react";
import {AdminUserModel} from "../../modules/admin-users/admin-user.model";
import {adminUserFields} from "../../modules/admin-users/admin-user.fields";
import {AdminUserController} from "../../modules/admin-users/admin-user.controller";

export const AdminUsers = () => {
    const route = useCurrentRoute();
    const navigation = useNavigation()
    const auth = useAuth();
    const errorHandler = useErrorHandler();
    const {actions,loading,data,setData} = useController(
        ({setData}) => {
            const model = new AdminUserModel(BACKEND_BASE_URL + "/admin",auth)
            return new AdminUserController(model, navigation, route.data.base, errorHandler,false,setData)
        },
        {asyncActions:true,stateless:false,logging:true},
        [auth,navigation,route,errorHandler]
    )
    return <CrudListView fields={adminUserFields} {...{data,actions,loading}} />

}
