import React, {useState} from 'react';
import {LoginView} from "../../login/LoginView";
import {useController} from "../../mvc/mvc.hook";

export const Login = () => {
    const [error,setError] = useState(null)
    async function onLogin(username,password) {
        await new Promise(resolve => setTimeout(resolve,1000))
        setError((prev) => {
            if (!prev) return 'Invalid Credentials'
            else return null
        })

    }
    const {actions,loading} = useController(() => ({onLogin}),{asyncActions: true})
    return <LoginView {...{actions,loading,error}}/>
}
