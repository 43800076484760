import React from 'react';
import {Alert, Button, Card, Form, Input} from "antd";

export const LoginView = ({actions,loading,error}) => {
    const [form] = Form.useForm()
    const onFinish = (values) => {
        actions.onLogin && actions.onLogin(values.username,values.password)
    };

    return (
        <Card title="Login">
            {error && <Alert style={{marginBottom:"15px"}} message={error} type="error" showIcon banner />}
            <Form form={form} layout={'vertical'} onFinish={onFinish}>
                <Form.Item name="username" label="Username">
                    <Input />
                </Form.Item>
                <Form.Item name="password" label="Password">
                    <Input type={'password'} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
