import {CommonCrudController} from "../../crud/controller/common-crud.controller";
import AwesomeDebouncePromise from 'awesome-debounce-promise';

export class UserController extends CommonCrudController {

    search = async (query) => {
        try {
            this.data = await this.model.searchItem(query)
            this.updateCallback()
        } catch (e) {
            this.errorHandler(e)
        }
    }

    uploadFinished = () => {
        this.updateCallback()
    }

    _findInterestCallServer = AwesomeDebouncePromise(
        async (interest,name) => {
            console.log("find:",name)
            return await this.model.searchInterest(interest,name)
        },
        500
    );

    findInterest = async (interest,name) => {
        return await this._findInterestCallServer(interest,name)
    }

    addInterest = async (interest,userId,item) => {
        await this.model.addInterest(interest,userId,item)
        await this.reloadData()
    }

    removeInterest = async (interest,userId,name) => {
        await this.model.removeInterest(interest,userId,name)
        await this.reloadData()
    }

    save = async (item) => {
        const saved = await super.save(item)
        if (!item._id) {
            this.modify(saved)
        }
    }

}
