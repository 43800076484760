import React from 'react';
import {userFields} from "../../modules/users/user.fields";
import {useController} from "../../mvc/mvc.hook";
import {useCurrentRoute, useNavigation} from "react-navi";
import {UserController} from "../../modules/users/user.controller";
import {useErrorHandler} from "../../errors/error-handler";
import {CrudModifyItemView} from "../../crud/view/CrudModifyItemView";
import {useAuth} from "../../login/auth.hook";
import {UserModel} from "../../modules/users/user.model";
import {BACKEND_BASE_URL} from "../../api";
import {AdminUserModel} from "../../modules/admin-users/admin-user.model";
import {AdminUserController} from "../../modules/admin-users/admin-user.controller";
import {adminUserFields} from "../../modules/admin-users/admin-user.fields";

export const ModifyAdminUser = ({id,base}) => {
    const auth = useAuth();
    const navigation = useNavigation()
    const errorHandler = useErrorHandler();

    const {data,actions,loading} = useController(
        ({setData}) => {
            const model = new AdminUserModel(BACKEND_BASE_URL + "/admin",auth)
            return new AdminUserController(model,navigation,base,errorHandler,id,setData)
        },
        {
            asyncActions: true
        },
        [auth,navigation]
    )
    return <CrudModifyItemView {...{data,actions,loading,base}} fields={adminUserFields} name={'Admin User'}/>
}
