import {LoggedOutWarning} from "../../errors/LoggedOutWarning";
import {AppError} from "../../errors/AppError";
import {HttpRequestError} from "../../errors/HttpRequestError";

export class ModelBase {

    apiBase
    auth

    constructor(apiBase,auth) {
        this.apiBase = apiBase
        this.auth = auth
    }

    getApiBase = () => {
        return this.apiBase
    }

    getHeaders = (method,multipart=false) => {
        return {
            'Authorization':'Bearer '+this.auth.accessToken,
            ...((method!=="GET" && !multipart) ? {'Content-Type': 'application/json'} : {})
        }
    }

    request = async (options) => {
        const response = await fetch(this.apiBase+options.path,{
            method: options.method || 'GET',
            headers:{
                'Authorization':'Bearer '+this.auth.accessToken,
                ...(options.body ? {'Content-Type': 'application/json'} : {})
            },
            body: JSON.stringify(options.body)
        })

        if (response.status >= 200 && response.status < 300) {
            if (response.status === 204 || options.ignoreResult) {
                return true
            } else {
                return await response.json()
            }
        } else if (response.status === 401) {
            this.auth.logout()
            throw new LoggedOutWarning()
        } else {
            if (response.status === 406) {
                throw new HttpRequestError(response.status,response)
            } else {
                throw new AppError("Invalid Server Response")
            }
        }
    }

}