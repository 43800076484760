import {ModelBase} from "../../crud/model/model-base";

export class UserModel extends ModelBase {

    getDashboard = async () => {
        return await this.request({path: "/users/dashboard"})
    }

    getAll = async () => {
        return await this.request({path: "/users"})
    }

    getItem = async (id) => {
        return await this.request({ path: "/users/"+id })
    }

    searchItem = async (query) => {
        return await this.request({path:"/users?query="+encodeURIComponent(query)})
    }

    delete = async (id) => {
        return await this.request({method: "DELETE", path: "/users/"+id})
    }

    modify = async (item) => {
        return await this.request({method: "PUT", path: '/users/'+item._id, body: item})
    }

    create = async (item) => {
        return await this.request({method: "POST", path: '/users/', body: item})
    }

    searchBook = async (query) => {
        return await this.request({path:"/users/find-interest/books?query="+encodeURIComponent(query)})
    }

    addBook = async (userId,book) => {
        return await this.request({method: "POST", path:"/users/interest/"+userId+"/books", body: book})
    }

    removeBook = async (userId,name) => {
        return await this.request({method: "DELETE", path:"/users/interest/"+userId+"/books?name="+encodeURIComponent(name), ignoreResult: true})
    }

    searchInterest = async (interest,query) => {
        return await this.request({path:"/users/find-interest/"+interest+"?query="+encodeURIComponent(query)})
    }

    addInterest = async (interest,userId,item) => {
        return await this.request({method: "POST", path:"/users/interest/"+userId+"/"+interest+"", body: item})
    }

    removeInterest = async (interest,userId,name) => {
        return await this.request({method: "DELETE", path:"/users/interest/"+userId+"/"+interest+"?name="+encodeURIComponent(name), ignoreResult: true})
    }

}
