
class _MockUserModel {

    data = []
    initialized = false

    initializeTheDB() {
        for (let i = 0; i < 100; i++) {
            this.data.push({ _id: "user"+i, name: "User "+i })
        }
    }

    getApiBase = () => {
        return "/";
    }

    getHeaders = () => {
        return {}
    }

    async updateData() {
        await new Promise((resolve) => {
            window.setTimeout(() => {
                if (this.data.length === 0) this.initializeTheDB();
                resolve()
            },1000)
        })
    }

    async getAll() {
        await this.updateData()
        return this.data
    }

    async getItem(id) {
        if (!this.initialized) {
            await this.updateData()
        }
        await new Promise((resolve => window.setTimeout(resolve,1000)))
        console.log('getting',id)
        console.log(this.data)
        const item = this.data.filter(item => item._id === id)[0]
        return item
    }

    async delete(id) {
        await new Promise((resolve => window.setTimeout(resolve,1000)))
        this.data = this.data.filter(item => item._id !== id)
        return true
    }

    async create(item) {
        await new Promise((resolve => window.setTimeout(resolve,1000)))
        const savedItem = {_id: randomString(8), ...item}
        console.log("adding",savedItem)
        this.data.push(savedItem)
        return savedItem
    }

    async modify(item) {
        await new Promise((resolve => window.setTimeout(resolve,1000)))
        item.name = item.name && item.name.substring(0,10)
        const idx = this.data.findIndex((i) => i._id === item._id)
        this.data[idx] = {...this.data[idx], ...item}
    }

}

function randomString(len) {
    const p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    return [...Array(len)].reduce(a=>a+p[~~(Math.random()*p.length)],'');
}

export const MockUserModel = new _MockUserModel()
