import React from 'react';
import {useController} from "../../mvc/mvc.hook";
import {userFields} from "../../modules/users/user.fields";
import {useCurrentRoute, useNavigation} from "react-navi";
import {UserModel} from "../../modules/users/user.model";
import {useAuth} from "../../login/auth.hook";
import {UserController} from "../../modules/users/user.controller";
import {useErrorHandler} from "../../errors/error-handler";
import {BACKEND_BASE_URL} from "../../api";
import {CrudListView} from "../../crud/view/CrudListView";

export const Users = () => {
    const route = useCurrentRoute();
    const navigation = useNavigation()
    const auth = useAuth();
    const errorHandler = useErrorHandler();
    const {actions,loading,data,setData} = useController(
        ({setData}) => {
            const model = new UserModel(BACKEND_BASE_URL + "/admin",auth)
            return new UserController(model, navigation, route.data.base, errorHandler,false,setData)
        },
        {asyncActions:true,stateless:false,logging:true},
        [auth,navigation,route,errorHandler]
    )
    return <CrudListView fields={userFields} {...{data,actions,loading}} tableProps={{rowClassName:(record)=>(record?.banned?"user-banned":"")}} />
}
