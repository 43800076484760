import React from "react"

import {Alert, Breadcrumb, Drawer, Layout} from 'antd';
import {MenuFoldOutlined, MenuOutlined, MenuUnfoldOutlined,} from '@ant-design/icons';
import {useCurrentRoute, View} from "react-navi";
import {withNotifications} from "../notifications/notifications.hook";

const { Header, Content, Footer, Sider } = Layout;

const withCurrentRoute = (WrappedComponent) => {
    return (props) => {
        const currentRoute = useCurrentRoute()
        return (<WrappedComponent {...props} currentRoute={currentRoute} />)
    }
}

class AdminLayoutView extends React.Component {

    state = {
        collapsed: false,
        mobileMenuShown: false,
    };

    setMobileMenuShown(mobileMenuShown) {
        this.setState({
            mobileMenuShown
        })
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    render() {
        console.log(this.props.notifications)
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Drawer
                    placement="left"
                    onClose={() => this.setMobileMenuShown(false)}
                    closable={false}
                    visible={this.state.mobileMenuShown}
                    className="hideOnDesktop"
                    drawerStyle={{
                        "background": "#001529"
                    }}
                    bodyStyle={{
                        "padding": "0"
                    }}
                >
                    {this.props.navbar}
                </Drawer>
                <Sider
                    trigger={null}
                    collapsedWidth="0"
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                    className="hideOnMobile"
                >
                    {this.props.navbar}
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }}>
                        {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                            className: 'trigger hideOnMobile',
                            onClick: this.toggle,
                        })}
                        <MenuOutlined
                            className='trigger hideOnDesktop'
                            onClick={() => this.setMobileMenuShown(true)}
                        />
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        {
                            this.props.breadcrumb
                            ||
                            (
                                this.props.currentRoute?.title &&
                                <Breadcrumb style={{ margin: '16px 0' }}>
                                    <Breadcrumb.Item>{this.props.currentRoute?.title}</Breadcrumb.Item>
                                </Breadcrumb>
                            )
                        }
                        {/*<Breadcrumb style={{ margin: '16px 0' }}>
                            <Breadcrumb.Item>User</Breadcrumb.Item>
                            <Breadcrumb.Item>Bill</Breadcrumb.Item>
                        </Breadcrumb>*/}
                        {!!this.props.notifications && !!this.props.notifications.length && <div style={{padding: "10px 0", width: "100%", margin: "0 auto"}}>
                            {this.props.notifications.map(m => <Alert message={m.message} type={m.type} showIcon closable onClose={() => this.props.removeNotification(m)} />)}
                        </div>}
                        <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                            <View />
                            {/*{this.props.children}*/}
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
                </Layout>
            </Layout>
        );
    }
}

export const AdminLayout = withNotifications(withCurrentRoute(AdminLayoutView))